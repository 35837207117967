import React from "react";
import yes from '../resources/yes.png';
import no from '../resources/no.png';
import './penalty-shootout-result.css';

const PenaltyShootoutResult = ({ penaltyLog, team1, team2 }) => {
    const getTeamPenalties = (teamName) => {
        return penaltyLog
            .filter(penalty => penalty.team === teamName)
            .map((penalty, index) => (
                <div key={index} className={`penalty-item ${penalty.scored ? 'scored' : 'missed'}`}>
                    <span className="penalty-player">{penalty.name}</span>
                    <img
                        className="penalty-status-image"
                        src={penalty.scored ? yes : no}
                        alt={penalty.scored ? 'Scored' : 'Missed'}
                    />
                </div>
            ));
    }

    return (
        <div className="penalty-shootout-container">
            <div className="team-penalties">
                <h3>{team1}</h3>
                <div className="penalty-list">{getTeamPenalties(team1)}</div>
            </div>
            <div className="team-penalties">
                <h3>{team2}</h3>
                <div className="penalty-list">{getTeamPenalties(team2)}</div>
            </div>
        </div>
    );
}

export default PenaltyShootoutResult;