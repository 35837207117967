import React, { useState } from 'react';
import { MatchResult } from './LeagueSimulator';
import './knockout-result.css';
import './league-simulator.css';
import PenaltyShootoutResult from './PenaltyShootoutResult';

const KnockoutResult = ({ knockoutResults, logos, handleFixtureClick, displayFixtures }) => {
    const [activeStageKey, setActiveStageKey] = useState(Object.keys(knockoutResults)[0]);

    const [visibleFormationId, setVisibleFormationId] = useState(null);

    const toggleFormation = (matchId) => {
        if (visibleFormationId === matchId) {
            setVisibleFormationId(null); // Close the currently opened formation
        } else {
            setVisibleFormationId(matchId); // Open the new formation and close any other
        }
    };

    const handleTabClick = (stageKey) => {
        setActiveStageKey(stageKey);
    };

    const getRound = (stage) => {
        if (stage > 8) {
            return `Round of ${stage}`;
        } else if (stage === 8) {
            return 'Quarter Finals';
        } else if (stage === 4) {
            return 'Semi Finals';
        } else if (stage === 2) {
            return 'Final';
        }
        return '';
    };

    const activeResults = knockoutResults[activeStageKey];
    const firstLegs = activeResults?.firstLegResults || [];

    return (
        <div className="knockout-result-container matchday-results">
            <h2>Knockout Stage Results</h2>
            <div className="tab-container">
                {Object.keys(knockoutResults).sort((a, b) => b - a).map((stageKey) => (
                    <button
                        key={stageKey}
                        className={`tab-button ${activeStageKey === stageKey ? 'active-tab' : ''}`}
                        onClick={() => handleTabClick(stageKey)}
                    >
                        {getRound(parseInt(stageKey))}
                    </button>
                ))}
            </div>

            <div className="knockout-stage-results matchday-results">
                <h3>{getRound(parseInt(activeStageKey))}</h3>
                <div className="knockout-matches">
                    {activeStageKey === '2' ? (
                        activeResults && (
                            <div className="matchday-result matchday-results">
                                <MatchResult
                                    key={`${activeStageKey}-0`}
                                    result={activeResults}
                                    logos={logos}
                                    toggleFormation={toggleFormation}
                                    isFormationVisible={visibleFormationId === `${activeStageKey}-${activeResults.id}`}
                                    stageKey={activeStageKey}
                                />
                                {activeResults.penalties && (
                                    <PenaltyShootoutResult
                                        penaltyLog={activeResults.penalties.penaltyLog}
                                        team1={activeResults.team1.displayName}
                                        team2={activeResults.team2.displayName}
                                    />
                                )}
                            </div>
                        )
                    ) : (
                        firstLegs.map((firstLeg, index) => {
                            const secondLeg = knockoutResults[activeStageKey]?.secondLegResults[index];
                            const updatedSecondLeg = {
                                ...secondLeg,
                                homeGoals: secondLeg.homeGoals - firstLeg.awayGoals,
                                awayGoals: secondLeg.awayGoals - firstLeg.homeGoals
                            };
                            const firstKey = `${activeStageKey}-${firstLeg.id}`;
                            const secondKey = `${activeStageKey}-${secondLeg.id}`
                            return (
                                <div
                                    key={firstKey}
                                    className="matchday-result matchday-results"
                                    onClick={() => handleFixtureClick(firstLeg, secondLeg)}
                                >
                                    <MatchResult
                                        key={firstKey}
                                        result={firstLeg}
                                        logos={logos}
                                        toggleFormation={toggleFormation}
                                        isFormationVisible={visibleFormationId === firstKey}
                                        stageKey={activeStageKey}
                                    />
                                    <MatchResult
                                        key={secondKey}
                                        result={updatedSecondLeg}
                                        logos={logos}
                                        toggleFormation={toggleFormation}
                                        isFormationVisible={visibleFormationId === secondKey}
                                        stageKey={activeStageKey}
                                    />
                                    {secondLeg.penalties && (
                                    <PenaltyShootoutResult
                                        penaltyLog={secondLeg.penalties.penaltyLog}
                                        team1={secondLeg.team1.displayName}
                                        team2={secondLeg.team2.displayName}
                                    />
                                )}
                                </div>
                            );
                        })
                    )}
                </div>
            </div>

            {displayFixtures.length > 0 && (
                <div className="display-fixtures">
                    <h3>Selected Match Fixtures</h3>
                    {displayFixtures.map((fixture, index) => (
                        <MatchResult key={index} result={fixture} logos={logos} />
                    ))}
                </div>
            )}
        </div>
    );
}

export default KnockoutResult;