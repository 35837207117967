const DB_NAME = "AppDB";
const STORE_NAME = "PlayerData";

export const openDB = () => {
    return new Promise((resolve, reject) => {
        const request = indexedDB.open(DB_NAME, 1);

        request.onupgradeneeded = (event) => {
            const db = event.target.result;
            if (!db.objectStoreNames.contains(STORE_NAME)) {
                db.createObjectStore(STORE_NAME, {keyPath: "id"});
            }
        }

        request.onsuccess = (event) => resolve(event.target.result);
        request.onerror = (event) => reject(event.target.error);
    });
}

export const saveData = async (id, data) => {
    const db = await openDB();
    return new Promise((resolve, reject) => {
        const transaction = db.transaction(STORE_NAME, "readwrite");
        const store = transaction.objectStore(STORE_NAME);
        store.put({id, data, timestamp: Date.now()});
        transaction.oncomplete = () => resolve();
        transaction.onerror = (event) => reject(event.target.error);
    });
};

export const getData = async (id) => {
    const db = await openDB();
    return new Promise((resolve, reject) => {
        const transaction = db.transaction(STORE_NAME, "readonly");
        const store = transaction.objectStore(STORE_NAME);
        const request = store.get(id);
        request.onsuccess = () => resolve(request.result);
        request.onerror = (event) => reject(event.target.error);
    });
};

export const deleteData = async (id) => {
    const db = await openDB();
    return new Promise((resolve, reject) => {
        const transaction = db.transaction(STORE_NAME, "readwrite");
        const store = transaction.objectStore(STORE_NAME);
        store.delete(id);
        transaction.oncomplete = () => resolve();
        transaction.onerror = (event) => reject(event.target.error);
    });
};

export const savePlayersToCache = async (players, ipAddress) => {
    try {
        // Retrieve existing cache for the given IP
        const existingCache = await getData(ipAddress);

        let newPlayers = [];

        if (existingCache && existingCache.data.players) {
            // Merge existing players with new players, avoiding duplicates
            const existingPlayerIds = new Set(existingCache.data.players.map(p => p.id));
            newPlayers = [
                ...existingCache.data.players,
                ...players.filter(player => !existingPlayerIds.has(player.id)), // Add only new players
            ].map(p => {
                // Update existing players with new data if available
                const updatedPlayer = players.find(newPlayer => newPlayer.id === p.id);
                return updatedPlayer ? { ...p, ...updatedPlayer } : p;
            });
        } else {
            // If no existing cache, save all players as new
            newPlayers = players;
        }

        // Construct the new cache object
        const newCache = {
            players: newPlayers,
            timestamp: Date.now(),
        };

        // Save the updated cache in one transaction
        await saveData(ipAddress, newCache);
    } catch (error) {
        console.error(`Failed to save players in cache for IP: ${ipAddress}`, error);
    }
}

const savePlayerToCache = async (player, ipAddress) => {
    try {
        const existingCache = await getData(ipAddress);
        //console.log("Existing cache:", existingCache);

        let newPlayers = [];

        if (existingCache && existingCache.data.players) {
            const playerExists = existingCache.data.players.some(p => p.id === player.id);

            if (playerExists) {
                newPlayers = existingCache.data.players.map(p => 
                    p.id === player.id ? {...p, ...player} : p
                );
            } else {
                newPlayers = [...existingCache.data.players, player];
            }
        } else {
            newPlayers = [player];
        }
        const newCache = {
            players: newPlayers,
            timestamp: Date.now(),
        };

        await saveData(ipAddress, newCache);
    } catch (error) {
        console.error(`Failed to save player with ID ${player.id} in cache for IP: ${ipAddress}`, error);
    }
}