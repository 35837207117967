import React, { useState, useEffect, useRef } from 'react';
import { simulateMatch } from './SimulateMatch';
import { LeagueTable } from './LeagueSimulator';
import './champions-league-groups.css';
import { finalizeAppearances, simulateKnockoutRound, simulateMatchday, sortLeagueTable, updateLeagueTable } from './LeagueSimulation';
import KnockoutBracket from './KnockoutBracket';
import { assign } from 'lodash';

export const ChampionsLeagueGroups = ({ currentGroupIndex, setCurrentGroupIndex, leagueTable, logos }) => {
    const [localLeagueTable, setLocalLeagueTable] = useState(leagueTable);

    // Update local state when leagueTable prop changes
    useEffect(() => {
        setLocalLeagueTable(leagueTable);
    }, [leagueTable]);

    const handleNextGroup = () => {
        setCurrentGroupIndex((prevIndex) => (prevIndex + 1) % leagueTable.length);
    };

    const handlePrevGroup = () => {
        setCurrentGroupIndex((prevIndex) => (prevIndex - 1 + leagueTable.length) % leagueTable.length);
    };

    const handleGroupSelect = (event) => {
        setCurrentGroupIndex(Number(event.target.value));
    };

    return (
        <div className="champions-league-groups">
            <div className="navigation-container">
                <button onClick={handlePrevGroup} className="nav-button">
                    &larr; Prev
                </button>
                <select
                    className="group-dropdown"
                    value={currentGroupIndex}
                    onChange={handleGroupSelect}
                >
                    {localLeagueTable.map((group, index) => (
                        <option key={index} value={index}>
                            {group.groupName}
                        </option>
                    ))}
                </select>
                <button onClick={handleNextGroup} className="nav-button">
                    Next &rarr;
                </button>
            </div>
            <div className="group-title">
                <h2>{localLeagueTable[currentGroupIndex].groupName}</h2>
            </div>
            <LeagueTable teams={localLeagueTable[currentGroupIndex].leagueTable} logos={logos} />
        </div>
    )
};

const ChampionsLeagueSimulator = ({ leagueTable, logos, setFunctions, scheduledFixtures, formattedTeams, knockoutResults, setKnockoutResults, handleFixtureClick }) => {
    const [currentMatchday, setCurrentMatchday] = useState(0);
    const [allResults, setAllResults] = useState([]);
    const [currentLeg, setCurrentLeg] = useState(0);
    const [isSimulated, setIsSimulated] = useState(false);
    const [currentTeams, setCurrentTeams] = useState(formattedTeams);
    const [groupFixtures, setGroupFixtures] = useState([]);
    const [currentGroupIndex, setCurrentGroupIndex] = useState(0);
    const [activeTab, setActiveTab] = useState('groupStage');
    const [knockoutFixtures, setKnockoutFixtures] = useState([]);
    const [knockoutStage, setKnockoutStage] = useState(() => {
        const initialTeams = formattedTeams.length;
        const stage = Math.ceil(Math.log2(initialTeams)) - 1;
        return stage;
    });

    const playerCards = useRef({});
    const goalScorers = useRef({});
    const assisters = useRef({});
    const hatTricks = useRef({});
    const playerRatings = useRef({});
    const topGoalkeepers = useRef([]);

    useEffect(() => {
        const goalkeepers = [];
        formattedTeams.forEach(team => {
            const startingGoalkeeper = team.positionsData['Goalkeeper-0'];
            const benchGoalkeepers = team.bench.find(player => player.position.includes('GK'));
            const reserveGoalkeepers = team.reserves.filter(player => player.position.includes('GK'));

            const allGoalkeepers = [startingGoalkeeper, benchGoalkeepers, ...reserveGoalkeepers];
            allGoalkeepers.forEach(goalkeeper => {
                if (goalkeeper) {
                    goalkeepers.push({
                        name: goalkeeper.name,
                        fullName: goalkeeper.fullName,
                        country: goalkeeper.nationality,
                        team: team.displayName,
                        photo: goalkeeper.photo,
                        appearances: 0,
                        cleanSheets: 0,
                    });
                }
            });
        });

        topGoalkeepers.current = goalkeepers.sort((a, b) => b.cleanSheets - a.cleanSheets);
    }, []);

    const mapMatchdayFixtures = (fixtures, matchday) => {
        return fixtures.map(group => ({
            groupName: group.name,
            fixtures: group.fixtures[matchday] // Fetch map for current matchday
        }));
    }

    useEffect(() => {
        if (groupFixtures.length > 0 && activeTab === 'groupStage') {
            setFunctions.setFixtureResults(groupFixtures[currentGroupIndex].matchdays);
        }
    }, [currentGroupIndex]);

    const getPlayersAverageRating = (ratings) => {
        return Object.values(ratings)
            .map(player => {
                const averageRating = player.totalRating / player.appearances;
                const weightedScore = (averageRating * player.appearances) / (player.appearances + 10);

                return {
                    ...player,
                    averageRating,
                    weightedScore
                };
            })
            .sort((a, b) => {
                // First, sort by average rating (higher is better)
                if (b.weightedScore !== a.weightedScore) {
                    return b.weightedScore - a.weightedScore;
                }
                // If the average ratings are the same, sort by appearances (higher is better)
                return b.appearances - a.appearances;
            })
            .slice(0, 30);  // Take the top 30 players 
    }

    const simulateOneMatchday = async () => {
        //console.log('Scheduled fixtures in simulation:', scheduledFixtures)
        const numMatchdays = scheduledFixtures[0].fixtures.length

        if (currentMatchday < numMatchdays) {
            const fixturesByMatchday = [];
            for (let matchday = 0; matchday < numMatchdays; matchday++) {
                const matchdayFixtures = mapMatchdayFixtures(scheduledFixtures, matchday);
                fixturesByMatchday.push(matchdayFixtures);
            }

            //console.log('Fixtures by matchday:', fixturesByMatchday);

            const { roundResults, updatedTeams, leagueTable: newLeagueTable } = await simulateMatchday(currentMatchday, fixturesByMatchday, currentTeams, 99, playerCards.current, goalScorers.current, assisters.current, hatTricks.current, playerRatings.current, topGoalkeepers.current, leagueTable);
            //console.log("New round results:", roundResults, "\nNew league `table:", newLeagueTable);
            setCurrentTeams(updatedTeams);

            setCurrentMatchday((prevDay) => prevDay + 1);
            setAllResults((oldResults) => {
                const newFixtures = [...oldResults, roundResults];
                const groupedFixtures = scheduledFixtures.map(group => {
                    const matchdayFixtures = group.fixtures.map((matchday, matchdayIndex) => {
                        const matchdayResults = newFixtures.flat().filter(fixture =>
                            matchday.some(match =>
                                match.home.displayName === fixture.home && match.away.displayName === fixture.away
                            )
                        );

                        return matchdayResults;
                    });

                    return {
                        name: group.name,
                        matchdays: matchdayFixtures,
                    };
                });

                //console.log('Sorted by groups:', groupedFixtures);
                const sortedTable = sortLeagueTable(99, newLeagueTable, newFixtures);
                //console.log('Sorted table:', sortedTable);
                setFunctions.setLeagueTable(sortedTable);
                //console.log("All new fixtures:", newFixtures);
                setGroupFixtures(groupedFixtures);
                //console.log('Current group fixtures:', groupedFixtures[currentGroupIndex].matchdays)
                setFunctions.setFixtureResults(groupedFixtures[currentGroupIndex].matchdays);
                return newFixtures;
            });

            //finalizeAppearances(goalScorers, assisters, formattedTeams);
            setFunctions.setGoalScorers(goalScorers.current);
            setFunctions.setAssisters(assisters.current);
            setFunctions.setHatTricks(hatTricks.current);
            setFunctions.setTopPlayers(getPlayersAverageRating(playerRatings.current));
            const filteredTopGoalkeepers = topGoalkeepers.current
                .filter(goalkeeper => goalkeeper.cleanSheets > 0)
                .sort((a, b) => b.cleanSheets - a.cleanSheets)
                .slice(0, 20); // Get the top 20 goalkeepers

            setFunctions.setTopGoalkeepers(filteredTopGoalkeepers);
            //console.log('Group stage top players:', playerRatings.current);

            // if (currentMatchday >= 5) {
            //     console.log('Current table:', leagueTable);
            // }
        }
    };

    const assignFixtureDates = (knockoutFixtures, stageNum) => {
        let startDate;
        const days = ['Tuesday', 'Wednesday'];
        const finalDate = new Date('2025-05-31');

        const stageKey = 2 ** stageNum;
        const fixtures = knockoutFixtures[stageKey];

        if (!fixtures || fixtures.length === 0) {
            console.error(`No fixtures found for stageKey ${stageKey}`);
            return knockoutFixtures;
        }

        if (stageKey === 16) {
            startDate = new Date('2025-02-11');
        } else if (stageKey === 8) {
            startDate = new Date('2025-03-18');
        } else if (stageKey === 4) {
            startDate = new Date('2025-04-08');
        } else if (stageKey === 2) {
            const final = knockoutFixtures[stageKey][0];
            const finalWithDate = {
                ...final,
                date: finalDate.toISOString().split('T')[0],
                day: 'Saturday',
            }
            knockoutFixtures[stageKey] = [finalWithDate];
            return knockoutFixtures;
        }

        let fixtureIndex = 0;

        const getNextDate = (currentIndex, leg = 1) => {
            const date = new Date(startDate);
            const weekIncrement = Math.floor(currentIndex / 4) * 7; // After 4 matches, move to the next week
            const dayIncrement = (Math.floor(currentIndex / 2) % 2) * 1;
            const extraWeek = leg === 2 ? 7 : 0;
            date.setDate(startDate.getDate() + weekIncrement + dayIncrement + extraWeek);
            return date;
        }

        //Shuffle fixtures
        const shuffledFixtures = [...fixtures].sort(() => Math.random() - 0.5);

        shuffledFixtures.forEach(fixture => {
            const firstLegDate = getNextDate(fixtureIndex, 1);
            fixtureIndex++;
            fixture.legs[0].date = firstLegDate.toISOString().split('T')[0];
            fixture.legs[0].day = days[firstLegDate.getDay() % 2];

            // Assign second leg (one week after the first leg)
            if (stageKey !== 1) {
                const secondLegDate = getNextDate(fixtureIndex - 1, 2);
                fixture.legs[1].date = secondLegDate.toISOString().split('T')[0];
                fixture.legs[1].day = days[secondLegDate.getDay() % 2];
            }
        });

        //console.log('Shuffled fixtures with dates:', shuffledFixtures);
        knockoutFixtures[stageKey] = shuffledFixtures;

        return knockoutFixtures;
    };

    let retries = 0;
    const getRoundOf16Fixtures = async (table, teams) => {
        const pot1 = [];
        const pot2 = [];

        table.forEach(group => {
            const groupTeams = group.leagueTable;
            pot1.push(groupTeams[0]); // 1st Place team
            pot2.push(groupTeams[1]); // 2nd Place team
        })

        const shuffleArray = array => {
            for (let i = array.length - 1; i > 0; i--) {
                const j = Math.floor(Math.random() * (i + 1));
                [array[i], array[j]] = [array[j], array[i]];
            }
            return array;
        };

        const shuffledPot1 = shuffleArray([...pot1]);
        const shuffledPot2 = shuffleArray([...pot2]);

        const generateFixtures = () => {
            const kFixtures = [];
            for (let i = 0; i < shuffledPot1.length; i++) {
                const team1 = shuffledPot1[i];
                const team2 = shuffledPot2[i];

                const team1Object = teams.find(t => t.displayName === team1.displayName);
                const team2Object = teams.find(t => t.displayName === team2.displayName);

                if (Math.random() < 0.5) {
                    kFixtures.push({
                        team1: team1.displayName,
                        team2: team2.displayName,
                        legs: [
                            { home: team1Object, away: team2Object, leg: 1 },
                            { home: team2Object, away: team1Object, leg: 2 }
                        ]
                    });
                } else {
                    kFixtures.push({
                        team1: team2.displayName,
                        team2: team1.displayName,
                        legs: [
                            { home: team2Object, away: team1Object, leg: 1 },
                            { home: team1Object, away: team2Object, leg: 2 }
                        ]
                    });
                }
            }
            return kFixtures;
        };

        const validateFixtures = fixtures => {
            return fixtures.every(fixture =>
                fixture.legs[0].home.leagueId !== fixture.legs[0].away.leagueId
            );
        };

        // Resolve conflicts by swapping teams between fixtures
        const resolveConflicts = fixtures => {
            for (let i = 0; i < fixtures.length; i++) {
                const fixtureA = fixtures[i];
                const leagueIdA = fixtureA.legs[0].home.leagueId;

                for (let j = i + 1; j < fixtures.length; j++) {
                    const fixtureB = fixtures[j];
                    const leagueIdB = fixtureB.legs[0].home.leagueId;

                    // Check for conflicts in fixture A
                    if (fixtureA.legs[0].away.leagueId === leagueIdA) {
                        // Check if swapping with fixture B resolves the conflict
                        const canSwap = fixtureB.legs[0].away.leagueId !== leagueIdA &&
                            fixtureA.legs[0].away.leagueId !== leagueIdB;

                        if (canSwap) {
                            // Perform the swap
                            [fixtureA.legs[0].away, fixtureB.legs[0].away] = [fixtureB.legs[0].away, fixtureA.legs[0].away];
                            [fixtureA.legs[1].home, fixtureB.legs[1].home] = [fixtureB.legs[1].home, fixtureA.legs[1].home];
                            break; // Exit inner loop after resolving conflict
                        }
                    }
                }
            }
            return validateFixtures(fixtures);
        };

        let kFixtures = generateFixtures();

        while (!validateFixtures(kFixtures)) {
            if (retries < 50) {
                console.log("Conflicts detected, regenerating fixtures...");
                kFixtures = generateFixtures();
                resolveConflicts(kFixtures);
                retries++;
            }
        }

        return kFixtures;
    };

    const startKnockoutStage = async () => {
        const fixtures = await getRoundOf16Fixtures(leagueTable, currentTeams);
        //console.log('Fixtures starting:', fixtures);
        const stageKey = `${currentTeams.length / 2}`;
        const fixturesArray = { [stageKey]: fixtures };
        console.log("Fixtures array without dates:", fixturesArray);
        const fixturesWithDates = assignFixtureDates(fixturesArray, knockoutStage);
        console.log('Fixtures array:', fixturesWithDates);
        setKnockoutFixtures(fixturesWithDates);
        setActiveTab('knockoutBracket');
    }

    const handleSimulateKnockoutStage = async () => {
        if (knockoutStage !== 1) {
            if (!isSimulated) {
                const stageKey = `${2 ** knockoutStage}`;
                const { firstLegResults, secondLegResults, updatedTeams } = await simulateKnockoutRound(knockoutFixtures[stageKey], currentTeams, playerCards.current, goalScorers.current, assisters.current, hatTricks.current, playerRatings.current, topGoalkeepers.current);
                const results = { firstLegResults, secondLegResults };
                console.log('Knockout results:', results);
                setKnockoutResults((prevResults) => {
                    const newResults = {
                        ...prevResults,
                        [stageKey]: results
                    };
                    return newResults;
                });
                setFunctions.setGoalScorers(goalScorers.current);
                setFunctions.setAssisters(assisters.current);
                setFunctions.setHatTricks(hatTricks.current);
                //console.log('Hat tricks:', hatTricks);
                setFunctions.setTopPlayers(getPlayersAverageRating(playerRatings.current));
                setCurrentTeams(updatedTeams);
                setIsSimulated(true);
                setCurrentLeg(1);
            } else if (currentLeg === 1) {
                setCurrentLeg(2);
            }
        } else {
            if (!isSimulated) {
                const stageKey = `${2 ** knockoutStage}`;
                const result = await simulateKnockoutRound(knockoutFixtures[stageKey], currentTeams, playerCards, goalScorers, assisters, hatTricks, playerRatings, topGoalkeepers.current,);
                setKnockoutResults((prevResults) => {
                    const allResults = {
                        ...prevResults, [stageKey]: result
                    };
                    return allResults;
                });
                setIsSimulated(true);
                setFunctions.setGoalScorers(goalScorers.current);
                setFunctions.setAssisters(assisters.current);
                setFunctions.setHatTricks(hatTricks.current);
                setFunctions.setTopPlayers(getPlayersAverageRating(playerRatings.current));
            }
        }
    };

    const getNextRoundFixtures = (secondLegResults) => {
        if (knockoutStage === 2) {
            const shuffledWinners = secondLegResults.map((fixture) => {
                const { homeGoals, awayGoals, penalties } = fixture;

                const team1Aggregate = awayGoals;
                const team2Aggregate = homeGoals;

                if (team1Aggregate > team2Aggregate) {
                    return fixture.away;
                } else if (team2Aggregate > team1Aggregate) {
                    return fixture.home;
                } else if (penalties) {
                    return penalties.team1Score > penalties.team2Score ? fixture.away : fixture.home;
                }
                return null;
            }).sort(() => Math.random() - 0.5);

            const team1Object = currentTeams.find(t => t.displayName === shuffledWinners[0]);
            const team2Object = currentTeams.find(t => t.displayName === shuffledWinners[1]);

            return [{
                team1: shuffledWinners[0],
                team2: shuffledWinners[1],
                legs: [{
                    home: team1Object,
                    away: team2Object
                }]
            }];
        }
        const winners = secondLegResults.map((fixture) => {
            const { homeGoals, awayGoals, penalties } = fixture;

            const team1Aggregate = awayGoals;
            const team2Aggregate = homeGoals;

            if (team1Aggregate > team2Aggregate) {
                return fixture.away;
            } else if (team2Aggregate > team1Aggregate) {
                return fixture.home;
            } else if (penalties) {
                return penalties.team1Score > penalties.team2Score ? fixture.away : fixture.home;
            }
            return null;
        });
        //console.log('Winners of round:', winners);

        const shuffledWinners = [...winners].sort(() => Math.random() - 0.5);

        const nextRoundFixtures = [];
        for (let i = 0; i < shuffledWinners.length; i += 2) {
            const team1Object = currentTeams.find(t => t.displayName === shuffledWinners[i]);
            const team2Object = currentTeams.find(t => t.displayName === shuffledWinners[i + 1]);
            nextRoundFixtures.push({
                team1: shuffledWinners[i],
                team2: shuffledWinners[i + 1],
                legs: [
                    { home: team1Object, away: team2Object, leg: 1 },
                    { home: team2Object, away: team1Object, leg: 2 },
                ],
            });
        }

        return nextRoundFixtures;
    };

    const advanceToNextRound = () => {
        if (currentLeg === 2 && knockoutResults) {
            const stageKey = `${2 ** (knockoutStage)}`;

            const nextRoundFixtures = getNextRoundFixtures(knockoutResults[stageKey].secondLegResults);
            const newStageKey = `${2 ** (knockoutStage - 1)}`;
            setKnockoutFixtures((prevFixtures) => {
                const newFixtures = { ...prevFixtures, [newStageKey]: nextRoundFixtures };
                const newFixturesWithDates = assignFixtureDates(newFixtures, knockoutStage - 1);
                //console.log("New fixtures with dates:", newFixturesWithDates);
                return newFixturesWithDates;
            });
            setKnockoutStage((prevStage) => prevStage - 1);
            setCurrentLeg(0);
            setIsSimulated(false);
            // setKnockoutResults((prevResults) => ({
            //     ...prevResults,
            // }));
        }
    };

    const simulateManyChampionsLeague = async (numSimulations = 100) => {
        const winnerCounts = {};

        for (let i = 0; i < numSimulations; i++) {
            for (let matchday = 0; matchday < 6; matchday++) {

            }
        }
    }

    useEffect(() => {
        if (Array.isArray(knockoutResults) && knockoutResults.length === 0) {
            setActiveTab('groupStage');
            setCurrentMatchday(0);
        }
    }, [knockoutResults]);

    return (
        <div className="champions-league-simulator">
            {/* Tab System */}
            <div className="tabs">
                <button
                    className={`tab-button ${activeTab === 'groupStage' ? 'active' : ''
                        }`}
                    onClick={() => setActiveTab('groupStage')}
                >
                    Group Stage
                </button>
                {currentMatchday >= 6 && knockoutFixtures[`${currentTeams.length / 2}`]?.length > 0 && (
                    <button
                        className={`tab-button ${activeTab === 'knockoutBracket' ? 'active' : ''
                            }`}
                        onClick={() => setActiveTab('knockoutBracket')}
                    >
                        Knockout Stage
                    </button>
                )}
            </div>

            {/* Conditional Rendering Based on Active Tab */}
            {activeTab === 'groupStage' && (
                <ChampionsLeagueGroups
                    currentGroupIndex={currentGroupIndex}
                    setCurrentGroupIndex={setCurrentGroupIndex}
                    leagueTable={leagueTable}
                    logos={logos}
                />
            )}
            {activeTab === 'knockoutBracket' && knockoutFixtures[`${currentTeams.length / 2}`].length > 0 && (
                <KnockoutBracket
                    currentLeg={currentLeg}
                    knockoutStage={knockoutStage}
                    knockoutResults={knockoutResults}
                    fixturesWithDates={knockoutFixtures}
                    handleSimulateKnockout={handleSimulateKnockoutStage}
                    advanceToNextRound={advanceToNextRound}
                    handleFixtureClick={handleFixtureClick}
                    logos={logos}
                />
            )}

            {/* Matchday Simulation Button */}
            {activeTab === 'groupStage' && currentMatchday < 6 && (
                <button
                    onClick={simulateOneMatchday}
                    className="cl-next-matchday-button"
                >
                    Simulate Matchday {currentMatchday + 1}
                </button>
            )}
            {activeTab === 'groupStage' && currentMatchday >= 6 && (
                <button
                    onClick={startKnockoutStage}
                    className="cl-next-matchday-button"
                >
                    Start Knockout Stage
                </button>
            )}
        </div>
    );
};

export default ChampionsLeagueSimulator;