import React, { useState } from 'react';
import './SelectTeamsModal.css';

const SelectTeamsModal = ({ onClose, getPlayerObjects, savedFormations, setTeams, setFieldOpen }) => {
    const [homeTeam, setHomeTeam] = useState('');
    const [awayTeam, setAwayTeam] = useState('');

    const areTeamsDifferent = (homeTeamData, awayTeamData) => {
        const homePlayers = [...Object.values(homeTeamData.positionsData), ...homeTeamData.bench];
        const awayPlayers = [...Object.values(awayTeamData.positionsData), ...awayTeamData.bench];
        const homePlayerIds = new Set(homePlayers.map(player => player.id));
        const duplicatePlayers = awayPlayers.filter(player => homePlayerIds.has(player.id));
        //console.log('Duplicate players:', duplicatePlayers);

        return duplicatePlayers.length === 0;
    };

    const handleSubmit = () => {
        if (homeTeam && awayTeam && homeTeam !== awayTeam) {
            const homeTeamData = getPlayerObjects(savedFormations.find(team => team.displayName === homeTeam));
            const awayTeamData = getPlayerObjects(savedFormations.find(team => team.displayName === awayTeam));

            if (homeTeamData && awayTeamData) {
                if (areTeamsDifferent(homeTeamData, awayTeamData)) {
                    setTeams([
                        { ...homeTeamData, side: 'home' },
                        { ...awayTeamData, side: 'away' }
                    ]);
                    onClose();
                    setFieldOpen(true);
                } else {
                    alert('Home and Away teams cannot have the same players.');
                }
            } else {
                alert('One of the selected teams could not be found in the saved formations.');
            }
        } else {
            if (homeTeam === awayTeam) {
                alert('Home and Away teams cannot be the same.');
            } else {
                alert('Please select both home and away teams.');
            }
        }
    }

    return (
        <div className="modal">
            <div className="modal-content">
                <span className="close" onClick={onClose}>&times;</span>
                <h2>Select Home and Away Teams</h2>
                <select value={homeTeam} onChange={(e) => setHomeTeam(e.target.value)}>
                    <option value="">Select Home Team</option>
                    {savedFormations.map((team, index) => (
                        <option key={index} value={team.displayName}>{team.displayName}</option>
                    ))}
                </select>
                <select value={awayTeam} onChange={(e) => setAwayTeam(e.target.value)}>
                    <option value="">Select Away Team</option>
                    {savedFormations.map((team, index) => (
                        <option key={index} value={team.displayName}>{team.displayName}</option>
                    ))}
                </select>
                <button onClick={handleSubmit}>Set Teams</button>
            </div>
        </div>
    );
};

export default SelectTeamsModal;