import axios from "axios";
import { simulateMatch } from "./SimulateMatch";
import { backendUrl } from "../ItemTypes";
import { random } from "lodash";
import { generateFixtures } from "./LeagueSimulation";

const championsLeagueJson = {
    "RealMadrid": {
        "pot1": {
            "home": "Borussia Dortmund",
            "away": "Liverpool"
        },
        "pot2": {
            "home": "AC Milan",
            "away": "Atalanta"
        },
        "pot3": {
            "home": "FC Red Bull Salzburg",
            "away": "Lille"
        },
        "pot4": {
            "home": "VfB Stuttgart",
            "away": "Brest"
        }
    },
    "ManchesterCity": {
        "pot1": {
            "home": "Inter Milan",
            "away": "Paris Saint Germain"
        },
        "pot2": {
            "home": "Club Brugge",
            "away": "Juventus"
        },
        "pot3": {
            "home": "Feyenoord",
            "away": "Sporting CP"
        },
        "pot4": {
            "home": "Sparta Praha",
            "away": "Midtjylland"
        }
    },
    "FCBayernMünchen": {
        "pot1": {
            "home": "Paris Saint Germain",
            "away": "Barcelona FC"
        },
        "pot2": {
            "home": "Benfica",
            "away": "Shakhtar Donetsk"
        },
        "pot3": {
            "home": "Dinamo Zagreb",
            "away": "Feyenoord"
        },
        "pot4": {
            "home": "Midtjylland",
            "away": "Aston Villa"
        }
    },
    "ParisSaintGermain": {
        "pot1": {
            "home": "Manchester City",
            "away": "FC Bayern München"
        },
        "pot2": {
            "home": "Atlético Madrid",
            "away": "Arsenal"
        },
        "pot3": {
            "home": "PSV",
            "away": "FC Red Bull Salzburg"
        },
        "pot4": {
            "home": "Girona",
            "away": "VfB Stuttgart"
        }
    },
    "Liverpool": {
        "pot1": {
            "home": "Real Madrid",
            "away": "RB Leipzig"
        },
        "pot2": {
            "home": "Bayer 04 Leverkusen",
            "away": "AC Milan"
        },
        "pot3": {
            "home": "Lille",
            "away": "PSV"
        },
        "pot4": {
            "home": "Bologna",
            "away": "Girona"
        }
    },
    "InterMilan": {
        "pot1": {
            "home": "RB Leipzig",
            "away": "Manchester City"
        },
        "pot2": {
            "home": "Arsenal",
            "away": "Bayer 04 Leverkusen"
        },
        "pot3": {
            "home": "FK Bodø/Glimt",
            "away": "Young Boys"
        },
        "pot4": {
            "home": "AS Monaco",
            "away": "Sparta Praha"
        }
    },
    "BorussiaDortmund": {
        "pot1": {
            "home": "Barcelona FC",
            "away": "Real Madrid"
        },
        "pot2": {
            "home": "Shakhtar Donetsk",
            "away": "Club Brugge"
        },
        "pot3": {
            "home": "Celtic",
            "away": "Dinamo Zagreb"
        },
        "pot4": {
            "home": "Sturm Graz",
            "away": "Bologna"
        }
    },
    "RBLeipzig": {
        "pot1": {
            "home": "Liverpool",
            "away": "Inter Milan"
        },
        "pot2": {
            "home": "Juventus",
            "away": "Atlético Madrid"
        },
        "pot3": {
            "home": "Sporting CP",
            "away": "Celtic"
        },
        "pot4": {
            "home": "Aston Villa",
            "away": "Sturm Graz"
        }
    },
    "BarcelonaFC": {
        "pot1": {
            "home": "FC Bayern München",
            "away": "Borussia Dortmund"
        },
        "pot2": {
            "home": "Atalanta",
            "away": "Benfica"
        },
        "pot3": {
            "home": "Young Boys",
            "away": "FK Bodø/Glimt"
        },
        "pot4": {
            "home": "Brest",
            "away": "AS Monaco"
        }
    },
    "Bayer04Leverkusen": {
        "pot1": {
            "home": "Inter Milan",
            "away": "Liverpool"
        },
        "pot2": {
            "home": "AC Milan",
            "away": "Atlético Madrid"
        },
        "pot3": {
            "home": "FC Red Bull Salzburg",
            "away": "Feyenoord"
        },
        "pot4": {
            "home": "Sparta Praha",
            "away": "Brest"
        }
    },
    "AtléticoMadrid": {
        "pot1": {
            "home": "RB Leipzig",
            "away": "Paris Saint Germain"
        },
        "pot2": {
            "home": "Bayer 04 Leverkusen",
            "away": "Benfica"
        },
        "pot3": {
            "home": "Lille",
            "away": "FC Red Bull Salzburg"
        },
        "pot4": {
            "home": "Midtjylland",
            "away": "Sparta Praha"
        }
    },
    "Atalanta": {
        "pot1": {
            "home": "Real Madrid",
            "away": "Barcelona FC"
        },
        "pot2": {
            "home": "Arsenal",
            "away": "Shakhtar Donetsk"
        },
        "pot3": {
            "home": "Celtic",
            "away": "Young Boys"
        },
        "pot4": {
            "home": "Sturm Graz",
            "away": "VfB Stuttgart"
        }
    },
    "Juventus": {
        "pot1": {
            "home": "Manchester City",
            "away": "RB Leipzig"
        },
        "pot2": {
            "home": "Benfica",
            "away": "Club Brugge"
        },
        "pot3": {
            "home": "PSV",
            "away": "Lille"
        },
        "pot4": {
            "home": "VfB Stuttgart",
            "away": "Aston Villa"
        }
    },
    "Benfica": {
        "pot1": {
            "home": "Barcelona FC",
            "away": "FC Bayern München"
        },
        "pot2": {
            "home": "Atlético Madrid",
            "away": "Juventus"
        },
        "pot3": {
            "home": "Feyenoord",
            "away": "FK Bodø/Glimt"
        },
        "pot4": {
            "home": "Bologna",
            "away": "AS Monaco"
        }
    },
    "Arsenal": {
        "pot1": {
            "home": "Paris Saint Germain",
            "away": "Inter Milan"
        },
        "pot2": {
            "home": "Shakhtar Donetsk",
            "away": "Atalanta"
        },
        "pot3": {
            "home": "Dinamo Zagreb",
            "away": "Sporting CP"
        },
        "pot4": {
            "home": "AS Monaco",
            "away": "Girona"
        }
    },
    "ClubBrugge": {
        "pot1": {
            "home": "Borussia Dortmund",
            "away": "Manchester City"
        },
        "pot2": {
            "home": "Juventus",
            "away": "AC Milan"
        },
        "pot3": {
            "home": "Sporting CP",
            "away": "Celtic"
        },
        "pot4": {
            "home": "Aston Villa",
            "away": "Sturm Graz"
        }
    },
    "ShakhtarDonetsk": {
        "pot1": {
            "home": "FC Bayern München",
            "away": "Borussia Dortmund"
        },
        "pot2": {
            "home": "Atalanta",
            "away": "Arsenal"
        },
        "pot3": {
            "home": "Young Boys",
            "away": "PSV"
        },
        "pot4": {
            "home": "Brest",
            "away": "Bologna"
        }
    },
    "AC Milan": {
        "pot1": {
            "home": "Liverpool",
            "away": "Real Madrid"
        },
        "pot2": {
            "home": "Club Brugge",
            "away": "Bayer 04 Leverkusen"
        },
        "pot3": {
            "home": "Dinamo Zagreb",
            "away": "Girona"
        },
        "pot4": {
            "home": "Midtjylland",
            "away": "Slovan Bratislava"
        }
    },
    "Feyenoord": {
        "pot1": {
            "home": "FC Bayern München",
            "away": "Manchester City"
        },
        "pot2": {
            "home": "Bayer 04 Leverkusen",
            "away": "Benfica"
        },
        "pot3": {
            "home": "FC Red Bull Salzburg",
            "away": "Lille"
        },
        "pot4": {
            "home": "Sparta Praha",
            "away": "Girona"
        }
    },
    "SportingCP": {
        "pot1": {
            "home": "Manchester City",
            "away": "RB Leipzig"
        },
        "pot2": {
            "home": "Arsenal",
            "away": "Club Brugge"
        },
        "pot3": {
            "home": "Lille",
            "away": "PSV"
        },
        "pot4": {
            "home": "Bologna",
            "away": "Sturm Graz"
        }
    },
    "PSV": {
        "pot1": {
            "home": "Liverpool",
            "away": "Paris Saint Germain"
        },
        "pot2": {
            "home": "Shakhtar Donetsk",
            "away": "Juventus"
        },
        "pot3": {
            "home": "Sporting CP",
            "away": "FK Bodø/Glimt"
        },
        "pot4": {
            "home": "Girona",
            "away": "Brest"
        }
    },
    "DinamoZagreb": {
        "pot1": {
            "home": "Borussia Dortmund",
            "away": "FC Bayern München"
        },
        "pot2": {
            "home": "AC Milan",
            "away": "Arsenal"
        },
        "pot3": {
            "home": "Celtic",
            "away": "FC Red Bull Salzburg"
        },
        "pot4": {
            "home": "AS Monaco",
            "away": "Midtjylland"
        }
    },
    "FCRedBullSalzburg": {
        "pot1": {
            "home": "Paris Saint Germain",
            "away": "Real Madrid"
        },
        "pot2": {
            "home": "Atlético Madrid",
            "away": "Bayer 04 Leverkusen"
        },
        "pot3": {
            "home": "Dinamo Zagreb",
            "away": "Feyenoord"
        },
        "pot4": {
            "home": "Brest",
            "away": "Sparta Praha"
        }
    },
    "Lille": {
        "pot1": {
            "home": "Real Madrid",
            "away": "Liverpool"
        },
        "pot2": {
            "home": "Juventus",
            "away": "Atlético Madrid"
        },
        "pot3": {
            "home": "Feyenoord",
            "away": "Sporting CP"
        },
        "pot4": {
            "home": "Sturm Graz",
            "away": "Bologna"
        }
    },
    "FKBodø/Glimt": {
        "pot1": {
            "home": "Barcelona FC",
            "away": "Inter Milan"
        },
        "pot2": {
            "home": "Benfica",
            "away": "AC Milan"
        },
        "pot3": {
            "home": "PSV",
            "away": "Young Boys"
        },
        "pot4": {
            "home": "VfB Stuttgart",
            "away": "AS Monaco"
        }
    },
    "YoungBoys": {
        "pot1": {
            "home": "Inter Milan",
            "away": "Barcelona FC"
        },
        "pot2": {
            "home": "Atalanta",
            "away": "Shakhtar Donetsk"
        },
        "pot3": {
            "home": "FK Bodø/Glimt",
            "away": "Celtic"
        },
        "pot4": {
            "home": "Aston Villa",
            "away": "VfB Stuttgart"
        }
    },
    "Celtic": {
        "pot1": {
            "home": "RB Leipzig",
            "away": "Borussia Dortmund"
        },
        "pot2": {
            "home": "Club Brugge",
            "away": "Atalanta"
        },
        "pot3": {
            "home": "Young Boys",
            "away": "Dinamo Zagreb"
        },
        "pot4": {
            "home": "Midtjylland",
            "away": "Aston Villa"
        }
    },
    "Midtjylland": {
        "pot1": {
            "home": "Manchester City",
            "away": "FC Bayern München"
        },
        "pot2": {
            "home": "AC Milan",
            "away": "Atlético Madrid"
        },
        "pot3": {
            "home": "Dinamo Zagreb",
            "away": "Celtic"
        },
        "pot4": {
            "home": "VfB Stuttgart",
            "away": "Girona"
        }
    },
    "ASMonaco": {
        "pot1": {
            "home": "Barcelona FC",
            "away": "Inter Milan"
        },
        "pot2": {
            "home": "Benfica",
            "away": "Arsenal"
        },
        "pot3": {
            "home": "FK Bodø/Glimt",
            "away": "Dinamo Zagreb"
        },
        "pot4": {
            "home": "Aston Villa",
            "away": "Bologna"
        }
    },
    "SpartaPraha": {
        "pot1": {
            "home": "Inter Milan",
            "away": "Manchester City"
        },
        "pot2": {
            "home": "Atlético Madrid",
            "away": "Bayer 04 Leverkusen"
        },
        "pot3": {
            "home": "FC Red Bull Salzburg",
            "away": "Feyenoord"
        },
        "pot4": {
            "home": "Brest",
            "away": "VfB Stuttgart"
        }
    },
    "AstonVilla": {
        "pot1": {
            "home": "FC Bayern München",
            "away": "RB Leipzig"
        },
        "pot2": {
            "home": "Juventus",
            "away": "Club Brugge"
        },
        "pot3": {
            "home": "Celtic",
            "away": "Young Boys"
        },
        "pot4": {
            "home": "Bologna",
            "away": "AS Monaco"
        }
    },
    "Bologna": {
        "pot1": {
            "home": "Borussia Dortmund",
            "away": "Liverpool"
        },
        "pot2": {
            "home": "Shakhtar Donetsk",
            "away": "Benfica"
        },
        "pot3": {
            "home": "Lille",
            "away": "Sporting CP"
        },
        "pot4": {
            "home": "AS Monaco",
            "away": "Aston Villa"
        }
    },
    "Girona": {
        "pot1": {
            "home": "Liverpool",
            "away": "Paris Saint Germain"
        },
        "pot2": {
            "home": "Arsenal",
            "away": "AC Milan"
        },
        "pot3": {
            "home": "Feyenoord",
            "away": "PSV"
        },
        "pot4": {
            "home": "Midtjylland",
            "away": "Sturm Graz"
        }
    },
    "VfBStuttgart": {
        "pot1": {
            "home": "Paris Saint Germain",
            "away": "Real Madrid"
        },
        "pot2": {
            "home": "Atalanta",
            "away": "Juventus"
        },
        "pot3": {
            "home": "Young Boys",
            "away": "FK Bodø/Glimt"
        },
        "pot4": {
            "home": "Sparta Praha",
            "away": "Midtjylland"
        }
    },
    "SturmGraz": {
        "pot1": {
            "home": "RB Leipzig",
            "away": "Borussia Dortmund"
        },
        "pot2": {
            "home": "Club Brugge",
            "away": "Atalanta"
        },
        "pot3": {
            "home": "Sporting CP",
            "away": "Lille"
        },
        "pot4": {
            "home": "Girona",
            "away": "Brest"
        }
    },
    "Brest": {
        "pot1": {
            "home": "Real Madrid",
            "away": "Barcelona FC"
        },
        "pot2": {
            "home": "Bayer 04 Leverkusen",
            "away": "Shakhtar Donetsk"
        },
        "pot3": {
            "home": "PSV",
            "away": "FC Red Bull Salzburg"
        },
        "pot4": {
            "home": "Sturm Graz",
            "away": "Sparta Praha"
        }
    }
};

const shuffleArray = (array) => {
    for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]]; // Swap
    }
    return array;
};

const generateCLFixtures = (groups) => {
    const fixtures = [];
    groups.forEach(group => {
        const groupFixture = generateFixtures(group.teams);
        const firstHalf = groupFixture.slice(0, groupFixture.length / 2)
        const secondHalf = firstHalf
            .slice() // Create a shallow copy to avoid mutating firstHalf
            .reverse() // Reverse the order of matchdays
            .map(matchday => 
                matchday.map(match => ({
                    home: match.away,
                    away: match.home,
                }))
            );

        const orderedFixtures = [...firstHalf, ...secondHalf];
        
        fixtures.push({
            name: group.name,
            fixtures: orderedFixtures,
        });
    });

    //console.log("Fixtures:", fixtures);

    return fixtures;
};

const generateGroups = (teams) => {
    //console.log('Current teams:', teams);

    const generateGroupNames = (numberOfGroups) => {
        const groupNames = [];
        const alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";

        for (let i = 0; i < numberOfGroups; i++) {
            groupNames.push(`Group ${alphabet[i]}`);
        }

        return groupNames;
    };
    
    const validateGroups = (groups) => {
        return groups.every(group => {
            const leagueIds = group.teams.map(team => team.leagueId);
            return new Set(leagueIds).size === leagueIds.length;
        });
    };

    const resolveConflicts = (groups) => {
        const groupSize = groups[0].teams.length;

        for (let groupA of groups) {
            const leagueCountA = {};
            groupA.teams.forEach(team => {
                leagueCountA[team.leagueId] = (leagueCountA[team.leagueId] || 0) + 1;
            });

            // Identify duplicate league IDs in the group
            const duplicateLeagueIds = Object.keys(leagueCountA).filter(
                leagueId => leagueCountA[leagueId] > 1
            );

            for (let leagueId of duplicateLeagueIds) {
                const conflictingTeam = groupA.teams.find(team => team.leagueId === parseInt(leagueId));
                
                for (let groupB of groups) {
                    if (groupA === groupB) continue;

                    // Find a team in groupB that doesn't conflict in groupA
                    const swapCandidate = groupB.teams.find(team => 
                        !groupA.teams.some(t => t.leagueId === team.leagueId) &&
                        !groupB.teams.some(t => t.leagueId === conflictingTeam.leagueId)
                    );

                    if (swapCandidate) {
                        // Perform the swap
                        const indexA = groupA.teams.indexOf(conflictingTeam);
                        const indexB = groupB.teams.indexOf(swapCandidate);
                        [groupA.teams[indexA], groupB.teams[indexB]] = [groupB.teams[indexB], groupA.teams[indexA]];
                        break;
                    }
                }
            }
        }

        return validateGroups;  
    }

    const pots = {};

    teams.forEach(team => {
        if (!pots[team.potNumber]) pots[team.potNumber] = [];
        pots[team.potNumber].push(team.displayName);
    });

    //console.log("Pots:", structuredClone(pots));

    //Shuffle Pots:
    for (let pot = 1; pot <= 4; pot++) {
        pots[pot] = shuffleArray(pots[pot]);
    }
    const groupNames = generateGroupNames(teams.length / 4);
    //console.log('Group names:', groupNames);
    const groups = Array.from({ length: groupNames.length }, (_, index) => ({
        name: groupNames[index],
        teams: [],
    }));

    for (let groupIndex = 0; groupIndex < groups.length; groupIndex++) {
        for (let pot = 1; pot <= 4; pot++) {
            const teamName = pots[pot].pop();
            const teamObject = teams.filter(t => t.displayName === teamName)[0];    
            groups[groupIndex].teams.push(teamObject);
        }
    }

    if (!validateGroups(groups)) {
        const success = resolveConflicts(groups);
        if (!success) {
            throw new Error("Unable to resolve conflicts under the given constraints.");
        }
    }

    return groups;
};

const convertTeams = (teams) => {
    return teams.map(team => {
        const teamKey = team.team_name.replace(/ /g, "");
        const championsLeagueData = championsLeagueJson[teamKey] || {};

        return {
            name: team.team_name,
            CLMatches: championsLeagueData
        }
    });
}

const extractMatchesFromFixtures = (fixtures, teams) => {
    const matches = [];
    const seenMatches = new Set();

    const findTeamByName = (name) => teams.find(team => team.team_name === name);

    fixtures.forEach((teamFixture) => {
        const teamName = teamFixture.name;
        const teamMatches = teamFixture.CLMatches;

        Object.keys(teamMatches).forEach(pot => {
            const teams = teamMatches[pot];
            //console.log("Teams:", teams);
            Object.keys(teams).forEach(type => {
                const opponent = teams[type];
                let matchKey = '';
                let homeTeam = null, awayTeam = null;
                if (type === 'home') {
                    matchKey = generateMatchKey(teamName, opponent);
                    homeTeam = findTeamByName(teamName);
                    awayTeam = findTeamByName(opponent);
                } else {
                    matchKey = generateMatchKey(opponent, teamName);
                    homeTeam = findTeamByName(opponent);
                    awayTeam = findTeamByName(teamName);
                }

                if (!homeTeam || !awayTeam) {
                    console.warn(`One of the teams in the match was not found: ${teamName} vs ${opponent}`);
                    return;
                }
                if (!seenMatches.has(matchKey)) {
                    matches.push({ home: homeTeam, away: awayTeam });
                    seenMatches.add(matchKey);
                }
            })
        });
    });

    return matches;
};

const assignMatchdays = (adjList, totalMatchdays = 8, matchesPerDay = 18) => {
    const shuffleArray = (array) => {
        for (let i = array.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [array[i], array[j]] = [array[j], array[i]]; // Swap
        }
        return array;
    }

    const randomizeAdjList = (list) => {
        // Get all the keys (home teams)
        const teams = Object.keys(list);
    
        // Shuffle the home teams
        const shuffledTeams = shuffleArray(teams);
    
        // Create a new randomized adjacency list
        const randomizedAdjList = {};
        shuffledTeams.forEach(homeTeam => {
            // Shuffle the away teams for each home team
            randomizedAdjList[homeTeam] = shuffleArray([...list[homeTeam]]);
        });
    
        return randomizedAdjList;
    }

    const matchdays = Array.from({ length: totalMatchdays }, () => []);
    const teamsPlayedOnDay = Array.from({ length: totalMatchdays }, () => new Set());
    const matchesScheduled = new Set();

    const maxAttempts = 10000;
    let attempts = 0;

    while (attempts < maxAttempts) {  
        const randomizedAdjList = randomizeAdjList(adjList);
        for (const [homeTeam, awayTeams] of Object.entries(randomizedAdjList)) {
            for (const awayTeam of awayTeams) {
                const match = `${homeTeam}-${awayTeam}`;
                if (matchesScheduled.has(match)) continue;

                // Try to schedule the match in one of the matchdays
                for (let day = 0; day < totalMatchdays; day++) {
                    if (
                        !teamsPlayedOnDay[day].has(homeTeam) &&
                        !teamsPlayedOnDay[day].has(awayTeam)
                    ) {
                        matchdays[day].push({ home: homeTeam, away: awayTeam });
                        teamsPlayedOnDay[day].add(homeTeam);
                        teamsPlayedOnDay[day].add(awayTeam);
                        matchesScheduled.add(match);
                        break;
                    }
                }
            }
        }
        attempts++;
        if (matchdays[7].length >= 18) break; //Success
    }

    if (attempts >= maxAttempts) {
        console.log("Failed to find a valid schedule within the maximum attempts.");
    }
    return matchdays;
};

const generateMatchKey = (home, away) => {
    return `${home}-${away}`;
}

export { generateCLFixtures, extractMatchesFromFixtures, generateGroups };