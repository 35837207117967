import React, { useState, useEffect, useRef } from 'react';
import { fetchFlags, fetchLogos } from './SearchBar';
import axios from 'axios';
import unaccent from 'unaccent';
import { getPlayersFromTeam } from './AddPlayer';
import './team-viewer.css';
import { backendUrl } from './ItemTypes';

const TeamViewer = ({ createTeam, setHomeStadium, setCurrentTeam, setPlayersFromTeam, getPlayersFromTeam }) => {
    const [searchTerm, setSearchTerm] = useState('');
    const [searchResults, setSearchResults] = useState([]);

    const [flags, setFlags] = useState([]);
    const [logos, setLogos] = useState([]);

    const [flagsLoaded, setFlagsLoaded] = useState(false);
    const [logosLoaded, setLogosLoaded] = useState(false);
    const [showResults, setShowResults] = useState(false);
    const [isUpdatingTeams, setIsUpdatingTeams] = useState(false);
    const dropdownRef = useRef(null);

    //Sofifa function that is reliant on the website not blocking me
    //ITEM IS A TEAM OBJECT
    const getTeamFromId = async (teamId) => {
        try {
            const response = await axios.get(`${backendUrl}/api/league-teams/get/${teamId}`, {
                headers: {
                    'Content-Type': 'application/json',
                },
                withCredentials: true
            });

            //console.log("Response:", response.data);
            return response.data;
        }
        catch (error) {
            console.error("Error getting team from teamId:", error);
            return null;
        }

    };

    const handleSelectTeam = async (team) => {
        setSearchTerm('');
        setShowResults(false);
        console.log('Team name', team.name);

        await axios.get(`${backendUrl}/api/teams/fetch-teamId`, {
            params: { teamName: team.name },
            headers: {
                'Content-Type': 'application/json',
            },
            withCredentials: true
        })
            .then(async (response) => {
                console.log('Successful response for fetching teamID:', response.data);
                const teamId = response.data.team_id;
                if (isUpdatingTeams) {
                    await getPlayersFromTeam(team, teamId);
                    setHomeStadium(response.data['homeStadium']);
                } else {
                    const teamResponse = await getTeamFromId(teamId);
                    if (!teamResponse.success) {
                        alert('Team does not exist in database. Team may not be current');
                        setCurrentTeam(response.data);
                        createTeam(team);
                    } else {
                        const teamData = teamResponse.team;
                        //console.log('Team data:', teamData);
                        setCurrentTeam(teamData);
                        setPlayersFromTeam(teamData);
                        //getPlayersFromTeam(team, id);
                    }
                }
            })
            .catch((error) => {
                console.error('There was an error!', error);
            });
    }

    useEffect(() => {
        fetchLogos(setLogos, setLogosLoaded);
        fetchFlags(setFlags, setFlagsLoaded);
    }, []);

    const enhancedUnaccent = (text) => {
        // First, use unaccent to remove most accents
        let normalizedText = unaccent(text);

        // Manually replace any specific characters missed by unaccent
        normalizedText = normalizedText.replace(/ú/g, 'u');
        return normalizedText;
    };

    //Handles changing searchTerm by user
    useEffect(() => {
        if (setFlagsLoaded && setLogosLoaded) {
            const term = searchTerm.toLowerCase();

            if (term === '') {
                setSearchResults([]);
                return;
            }

            // Combine logos and flags for searching
            const combinedSearchItems = [
                ...logos.map(club => ({ type: 'club', name: club.club, image: club.logo })),
                ...flags.map(flag => ({ type: 'country', name: flag.nationality, image: flag.flag })),
            ];

            const matchingItems = combinedSearchItems.filter(item => {
                const refinedTerm = enhancedUnaccent(item.name);
                return refinedTerm.toLowerCase().includes(term)
            });

            setSearchResults(matchingItems);
            setShowResults(true);
        } else {
            setShowResults(false);
        }
    }, [searchTerm, flagsLoaded, logosLoaded]);

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
    };

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setShowResults(false);
        }
    };

    useEffect(() => {
        if (showResults) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        }
    }, [showResults]);

    return (
        <div className="search-and-switch-container">
            <div className="search-dropdown" ref={dropdownRef}>
                <input
                    type="text"
                    placeholder="Search for a club or national team..."
                    value={searchTerm}
                    onChange={handleSearchChange}
                    onFocus={() => setShowResults(true)}
                    autoFocus
                />
                {showResults && searchResults.length > 0 && (
                    <ul className="search-dropdown-results">
                        {searchResults.map((item, index) => (
                            <li key={index} onClick={() => handleSelectTeam(item)}>
                                <img src={item.image} alt={item.name} /> {item.name}
                            </li>
                        ))}
                    </ul>
                )}
            </div>
            <div className="team-switch-container">
                <div className="team-switch-text">Update Team?</div>
                <div className="team-switch">
                    <label>
                        <input
                            type="checkbox"
                            checked={isUpdatingTeams}
                            onChange={() => setIsUpdatingTeams((prev) => !prev)}
                        />
                        <span className="team-slider"></span>
                    </label>
                </div>
            </div>
        </div>
    );
}

export default TeamViewer;